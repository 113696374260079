<template>
  <div class="row" v-if="id && isLoading">
    <div class="col-12 text-center">
      <span class="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
    </div>
  </div>
  <div class="row" v-else>
    <div class="col-12 mt-2">
      <h3 class="border-bottom pb-2 mb-4">Criar Transmissão</h3>
    </div>
    <div class="col-12 mt-2 mb-2" v-if="!id">
    <label class="mb-1">Selecione o número destino</label>
    <select v-model="selectedContact" class="form-control">
      <option v-for="contact in contactsForSelect" :key="contact.phone_number" :value="contact.phone_number">
        {{ contact.name }} - {{ contact.phone_number}}
      </option>
    </select>
   </div>
    <div class="col-12 mb-2">
      <label class="mb-1">Nome</label>
      <input v-model="name" type="text" class="form-control" placeholder="Insira o nome da transmissão" :disabled="!!id">
    </div>
    <div class="col-12 mt-2 mb-2">
      <label class="mb-1">Mensagem</label>
      <!--suppress HtmlUnknownAttribute -->
      <textarea
          :disabled="!!id"
          v-model="body"
          class="form-control" rows="3"
          placeholder="Digite a mensagem ({{nome}} será substituido pelo nome do contato)"/>
    </div>
    <div class="col-12 mt-2 border-bottom pb-4" v-if="!id">
      <label class="col-12 mb-1" for="arquivo">Anexar Arquivo</label>
      <input type="file" class="form-control file" id="arquivo" ref="attach" @change="handleFileUpload">
    </div>
    <div class="col-12 mt-2 mb-2">
      <span v-if="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      <a target="_blank" class="mt-2" v-if="uploadUrl != ''" :href="uploadUrl">Arquivo</a>
      <button v-if="uploadUrl && !id" class="btn btn-danger btn-sm ml-1 mt-2" @click="uploadUrl = ''">X</button>
    </div>
    <div class="col-12 mt-2 input-group" v-if="!id">
      <input ref="contactInput" v-model="maskedValue" v-maska="contactValue" data-maska="(##) #####-####" type="text" class="form-control" @keydown.enter="addContact">
      <div class="input-group-append">
        <button class="btn btn-info" type="button" @click="addContact" :disabled="contactValue.unmasked?.length <= 9">Adicionar</button>
      </div>
      <div class="input-group-append">
        <button class="btn btn-success" @click="uploadExcel?.click()">Importar CSV</button>
        <input type="file" id="upload" style="display:none" ref="uploadExcel" @change="handleCsv">
      </div>
    </div>
    <div class="col-12" v-if="!id">
      <small id="emailHelp" class="form-text text-muted">
        O arquivo csv deve estar no layout [<b>numero</b>;<b>mensagem</b>;<b>nome</b>], sendo o nome não obrigatório
      </small>
    </div>
    <div class="col-12 dests pt-2" v-if="!!dests.length">
      <a href="#" @click.prevent="dests = []" v-if="!id">Limpar</a>
      <ul class="list-group">
        <li 
          v-for="item in dests" 
          :key="item.number" 
          class="list-group-item d-flex justify-content-between align-items-center wpp-font-color" 
          :class="{'bg-danger': item.messageStatus === MessageStatusEnum.FAIL}"
        >
          {{item.name !== item.number ? item.number + ' - ' + item.name : item.number}}
          <span v-if="item.messageStatus !== undefined" class="badge badge-pill" :class="item.messageStatus === MessageStatusEnum.READ ? 'read-message' : 'unread-message'">
            <font-awesome-icon :icon="getIconByStatus(item.messageStatus ?? 0)"/>
          </span>
          <a href="#" v-else @click.prevent="dests.splice(dests.indexOf(item), 1)">
            <font-awesome-icon class="text-danger" :icon="faBan" />
          </a>
        </li>
      </ul>
    </div>
    <div class="col-12 mt-4 pt-3 border-top">
      <div class="row">
        <div class="col-6 mt-2 pt-2 text-md-start">
          <router-link class="btn btn-primary mr-2" :to="{name: 'listTransmissions'}">Voltar</router-link>
        </div>
        <div class="col-6 mt-2 pt-2 text-md-end" v-if="!id">
          <button class="btn btn-info" @click="save" :disabled="dests.length === 0 || body.length === 0 || isLoading || !selectedContact" v-if="!isLoading">
            Salvar
          </button>
          <button class="btn btn-info" disabled v-else>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </button>
        </div>
        <div class="col-6 mt-2 pt-2 text-md-end" v-else>
          <button class="btn btn-info" @click="getTransmission" :disabled="isLoading" v-if="!isLoading">
            Atualizar
          </button>
          <button class="btn btn-info" disabled v-else>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">

import {onMounted, reactive, ref} from "vue";
import Papa from "papaparse"
import {MaskaDetail, vMaska} from "maska"
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faClock} from "@fortawesome/free-regular-svg-icons"
import {faCheck, faCheckDouble, faBan, faTimes, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'
import Destination from "@/models/Destination";
import {MessageStatusEnum} from "@/models/MessageStatusEnum";
import apiService from "@/services/api.service";
import transmissionService from "@/services/transmission.service";
import {useRoute, useRouter} from "vue-router";
import {AxiosResponse} from "axios";

const uploadExcel = ref<HTMLFormElement | null>(null);

const dests = ref<Destination[]>([]);
const contactInput = ref<HTMLInputElement | null>(null);
const body = ref('');
const name = ref('');

const contactValue = reactive({} as MaskaDetail);
const maskedValue = ref('');

const baseUrl = apiService.getBaseUrl();
const authHeader = apiService.getAuthHeader();
const isLoading = ref(false);
const isUploading = ref(false);
const attach = ref<HTMLInputElement | null>(null);
const uploadUrl = ref('');
const uploadFileName = ref('');
const route = useRoute();
const router = useRouter();
const id = parseInt(route.params.id as string);
const cloneId = parseInt(route.params.cloneId as string);

function addContact() {
  if (contactValue.unmasked) {
    dests.value.push({number: contactValue.unmasked, name: maskedValue.value});
    maskedValue.value = '';
    contactInput.value?.focus();
  }
}

function getIconByStatus(status: MessageStatusEnum) {
  console.log(status)
  switch (status) {
    case MessageStatusEnum.PENDING:
     return faClock;
    case MessageStatusEnum.FAIL:
    return faExclamationTriangle;
    case MessageStatusEnum.SENT:
      return faCheck;
    case MessageStatusEnum.DELIVERED:
    case MessageStatusEnum.READ:
      return faCheckDouble;
  }
}

function getNumberOnly(value: string) {
  return value.replace(/\D/g, '');
}

function clearNumber(value: string) {
  const numbers = getNumberOnly(value);
  return numbers
}

function handleFileUpload(event: Event) {
  const file = (event.target as HTMLInputElement).files?.[0];
  if (!file) return;
  uploadFileName.value = file.name;
  const size = (file.size / (1024 * 1024));
  if (size > 300) {
    alert('O arquivo deve ter no máximo 300mb');
    return false;
  }
  isUploading.value = true;
  transmissionService.uploadFile(file).then(function (res: AxiosResponse) {
    const {fileUrl} = res.data;
    uploadUrl.value = fileUrl;
  }).catch((error) => {
    console.log(error);
  }).finally(() => {
    isUploading.value = false;
  });
}

function save(){

  const data = {
    name: name.value,
    dests: dests.value,
    body: body.value,
    fileUrl: uploadUrl.value,
    fileName: uploadFileName.value,
    phoneNumber: selectedContact.value
  };

  isLoading.value = true;

  transmissionService.create(data).then((res) => {
    isLoading.value = false;
    router.replace({name: 'transmission', params: {id: res.data.id}}).then(() => {
      window.location.reload()
    });
  });

}

function handleCsv(event: Event) {
  const file = (event.target as HTMLInputElement).files?.[0];
  console.time('csv')
  if (file) {
    const reader = new FileReader();
    console.time('file')
    reader.onload = function (e) {
      console.timeEnd('file')
      const csv = e.target?.result;
      if (csv) {
        Papa.parse(csv as never, {
          worker: true,
          delimiter: ';',
          complete: function (results: { data: string[][] }) {
            results.data.forEach((item: string[]) => {
              if (item.length === 0) return;
              const number = clearNumber(item[0]);
              if (dests.value.some(dest => dest.number === number)) return;
              if (number.length < 9) return;
              const message = item[1]?.trim();
              const name = item[2]?.trim();
              if (item.length > 0) {
                dests.value.push({number, message, name: name !== '' ? name : number});
              }
            });
            console.log(dests.value)
            console.timeEnd('csv')
          }
        })
      }
    }
    reader.readAsText(file);
  }
}

function getTransmission(){
  if (id || cloneId) {
    isLoading.value = true;
    transmissionService.getById(id || cloneId).then(response => {
      const data = response.data;
      body.value = data.body;
      name.value = data.name;
      uploadUrl.value = data.file_url;
      if (cloneId && !!data.file_url){
        const aux = data.file_url.split('/');
        uploadFileName.value = aux[aux.length - 1];
      }
      dests.value = data.messages.map((dest: any) => {
        return {
          number: dest.contact.number,
          name: dest.contact.name,
          messageStatus: cloneId ? undefined : parseInt(dest.wpp_message_status)
        }
      });
      isLoading.value = false;
    });
  }
}

onMounted(() => {
  getTransmission()
});

interface Contact {
    phone_number: string;
    name: string;
  }


const contactsForSelect = ref<Contact[]>([]);
const selectedContact = ref<string>('');
console.log(selectedContact)

const getPhoneList = async () => {
  try {
    const response = await transmissionService.getPhoneList();
    contactsForSelect.value = response.data as Contact[];
  } catch (error) {
    console.error('Erro ao obter lista de contatos:', error);
  }
};

onMounted(() => {
  getPhoneList();
});

</script>

<style lang="scss">

.dests {
  max-height: 400px;
  overflow: auto;
}

.wpp-font-color{
  background-color: #005c4b;
  color: white;

  .read-message {
    color: #53bdeb;
  }

  .unread-message {
    color: #d5d5d5;
  }

  span {
    color: hsla(0,0%,100%,0.5);
    font-size: 100%;
  }

}
</style>